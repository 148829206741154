<template>
  <div class="w-100 my-5">
    <div class="my-5">
      <span class="font-15 font-bold">VIP Ticket Link: {{ transactionTempList.length }}</span>
    </div>
    <div class="row my-5" v-if="(transactionTempList.length > 0)">
      <div class="col-sm-12" v-for="(item, index) in transactionTempList" :key="(item, index)">
        <v-card class="my-2 box-white d-flex flex-column">
          <div class="m-2 row">
            <span class="col-sm-3">{{ item.referenceId }}</span>
            <span class="col-sm">{{ item.organizerId }}</span>
            <span class="col-sm-2">{{ item.emailList.join('\n') }}</span>
            <span class="col-sm-3">{{ JSON.stringify(item.details) }}</span>
            <span class="col-sm">{{ getDateStringFromTimestamp(item.createdAt) }}</span>
            <v-btn class="ml-auto mr-10" icon small @click="deleteTransactionTempInfoTask(item.referenceId)">
              <i class="flaticon-delete color-red"></i>
            </v-btn>
          </div>
        </v-card>
      </div>
    </div>
    <div class="py-40 center" v-else>
      <span class="font-20 alpha-07">{{ $t('no_data_found') }}</span>
    </div>
  </div>
</template>

<script>
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../main';
import { getDateStringFromTimestamp, showLoading } from '../../../functions';

export default {
  name: 'VIPTicketLink',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    transactionTempList() {
      return this.$store.state.transactionTempList.filter(element => element.referenceId.startsWith(this.PAYMENT_TYPE_VIP));
    }
  },
  mounted() {
    if (window.location.hostname !== 'localhost') {
      this.$router.push('/');
    }
  },
  methods: {
    getDateStringFromTimestamp,
    deleteTransactionTempInfoTask(referenceId) {
      const params = {
        functionName: 'deleteTransactionTempInfo',
        referenceId: referenceId
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>